<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card no-body>
        <div class="card-header">
          <!-- Title & SubTitle -->
          <div>
            <b-card-title></b-card-title>
            <b-card-sub-title></b-card-sub-title>
          </div>
          <!-- Card Actions -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
              </li>
            </ul>
          </div>
        </div>
        <div>
          <b-sidebar id="sidebar-1" title="Comparación Mezclas" shadow bg-variant="dark" text-variant="light"
            width="450px" right backdrop>
            <div class="px-2 py-2">
              <p>
                En esta secci&oacute;n usted podr&aacute; visualizar las comparaciones de los resultados para cada mezcla
                que
                seleccion&oacute;
              </p>
              <hr>
              <p><strong>Gr&aacute;fico Comparaci&oacute;n de Variables:</strong> Usted podr&aacute; encontrar la
                comparaci&oacute;n con respecto a variables tales
                como las calidades de los analitos y a ciertas restricciones que eran de importancia en el proyecto. El
                eje X corresponde a las leyes de analitos, humedad y restricciones. El eje Y corresponde al porcentaje
                de concentrado.
              </p>
              <p><strong>Gr&aacute;fico Comparaci&oacute;n Libras Molibdeno:</strong> Usted podr&aacute; encontrar la
                comparaci&oacute;n entre las mezclas con
                respecto a las cantidades de Libras de Molibdeno, donde el eje X corresponde a las mezclas y el eje Y a
                las cantidades Libras Molibdeno.
              </p>
            </div>
          </b-sidebar>
        </div>

        <!-- Card Body  -->
        <b-card-body>
          <ag-grid-vue style="width: 100%; height: 450px" class="ag-theme-alpine" :columnDefs="columnDefs"
            :rowData="rowData" :pinnedTopRowData="pinnedTopRows"></ag-grid-vue>
        </b-card-body>
      </b-card>

      <bruna-element-loader :dataUrl="compareBlendingMetricsParallel" ref="bel0">
      </bruna-element-loader>

      <bruna-element-loader :dataUrl="compareBlendingMetrics">
      </bruna-element-loader>

      <bruna-element-loader :dataUrl="compareBlendingMetricsKg">
      </bruna-element-loader>

      <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..." />
          <p id="cancel-label">Cargando Resultados...</p>
        </div>
      </template>
      <b-button @click="goDetalleOptimizacion()"
        style="height: 50px; background-color: #053743 !important; float:right; margin-right: 20px;  margin-top: 20px; padding-top: 17px;">
        Volver a Detalle Optimización</b-button>
    </b-overlay>
  </div>
</template> 
  
<script>


import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import { AgGridVue } from "ag-grid-vue";
import axios from '@axios'

import {
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";

var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';

export default {
  data() {
    let optimization_id = this.$route.query.optimization_id;
    let mezclas = this.$route.query.mezclas;
    // console.log(this.$route.query)
    let query = qs.stringify({ optimization_id: optimization_id, mezclas: mezclas })
    // console.log(query)

    return {
      showOverlay: false,
      compareBlendingMetrics: useApiServices.compareBlending.Metrics + "?" + query,
      compareBlendingMetricsParallel: useApiServices.compareBlending.MetricsParallel + "?" + query,
      compareBlendingMetricsKg: useApiServices.compareBlending.MetricsKg + "?" + query,

      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],
      turnos: ''
    };
  },

  mounted() {

    let optimization_id = this.$route.query.optimization_id;
    let mezclas = this.$route.query.mezclas;
    // console.log(this.$route.query)
    let query = qs.stringify({ optimization_id: optimization_id, mezclas: mezclas })
    // console.log(query)
    axios.request({
      method: "get",
      url: useApiServices.compareBlending.MetricsTable + "?" + query,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {

        this.columnDefs = response.data.columnDefs;
        this.rowData = response.data.rowData;
        this.pinnedTopRows = response.data.pinnedTopRows;
      })
  },

  created() {

  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridVue
  },

  methods: {
    goDetalleOptimizacion() {
      let optimization_id = this.$route.query.optimization_id;

      this.$router.push({
        name: 'molymet-detalle-optimizacion', query: {
          optimization_id: optimization_id
        }
      })
    },
  },

};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
  
<style >
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}

.icon-plan {
  padding-top: 5%;
}


.ag-row-pinned {
  background-color: #F0E68C !important;
}
</style>
  

<style>
div.ag-watermark {
  display: none !important;
}
</style>
